/* Google Font Imports */
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Josefin+Sans:wght@300;400&family=Nunito&display=swap");

/* variables */
:root {
  --width: 1280px;
  --main-color: #55c57a;
  --light-color: #7ed56f;
  --medium-color: #28b485;
  --grey-color-light: #f7f7f7;
  --grey-color-dark: #777777;
  --dark-color: #333333;
  /* --bg-image: url("../img/slideshow/img.jpg"); */
}

/* defaults */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  line-height: 1.6;
  color: var(--grey-color-dark);
}

html {
  /* This defines what 1rem is */
  font-size: 62.5%;
  font-family: "Nunito", sans-serif;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}
.container {
  max-width: 1450px;
  margin: 0 auto;
}

/* Utils */
h2 {
  font-family: "Bebas Neue", cursive;
  font-size: 5rem;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 3px;
  line-height: 72px;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: var(--main-color);
  padding: 2rem;
}
h3 {
  position: relative;
  display: block;
  font-family: "Josefin Sans", sans-serif;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  font-size: 1.8rem;
  margin: 1.5rem 0;
}
h4 {
  font-weight: bold;
  font-family: "Nunito", sans-serif;
}
p {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 22.3999996185303px;
  text-transform: none;
  color: #ffffff;
}
li {
  list-style: none;
  text-decoration: none;
}
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 4rem;
}
.align-center {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.row {
  display: grid;
  grid-template-columns: 50% 50%;
}
.column {
  margin: 2rem 2rem 4rem 4rem;
}

/* Headers */
.section-header {
  text-transform: uppercase;
  padding: 7rem 0rem 1rem 0;
}
/* Gallery IMG */
.react-photo-gallery--gallery {
  margin: 2rem;
  padding: 0 2rem;
}
.react-photo-gallery--gallery img {
  object-fit: cover;
  opacity: 1;
  transition: 0.3s ease-in-out;
}
.react-photo-gallery--gallery img:hover {
  opacity: 0.5;
}

/* Media Queries */

@media only screen and (max-width: 600px) and (min-width: 320px) {
  h2 {
    line-height: 5rem;
  }
  .navbar {
    flex-direction: column;
    padding: 0;
  }
  .navbar .logo img {
    height: 4rem;
    margin-top: 10px;
  }
  .navbar li {
    padding: 0;
  }
  .navbar a {
    padding: 0.5rem;
    font-size: 1.1rem;
  }

  .slider .content h1 {
    font-size: 7rem;
    line-height: 6rem;
  }
  .slider .content p {
    font-size: 1.8rem;
  }
  .slider .content {
    width: 30rem;
    height: 100%;
  }
  .buttons #next {
    position: absolute;
    top: 90%;
    right: 15px;
  }

  .buttons #prev {
    position: absolute;
    top: 90%;
    left: 15px;
  }
  .section-about {
    background-image: linear-gradient(
        105deg,
        rgba(85, 197, 122, 0.9) 0%,
        rgba(85, 197, 122, 0.9) 100%,
        transparent 50%
      ),
      url(/img/about.jpg);
  }
  .row {
    grid-template-columns: 1fr;
  }

  .column {
    margin: 2rem;
  }

  .cards {
    display: flex;
    flex-direction: column-reverse;
    background-image: none;
    min-height: 28rem;
    min-width: auto;
  }
  .cards-sections {
    padding: 2rem;
  }
  .img-1,
  .img-2,
  .img-3,
  .img-4 {
    background-color: var(--main-color);
    margin-bottom: 0;
    height: 10rem;
  }

  .section-contact {
    background-image: linear-gradient(
        102deg,
        rgba(85, 197, 122, 0.9) 0%,
        rgba(85, 197, 122, 0.9) 100%,
        transparent 50%
      ),
      url(/img/address-map.png);
  }
  .contact-logo img {
    height: 5rem;
  }
  .contact-info p {
    text-align: left;
    font-size: 1.5rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 1024px) and (min-width: 601px) {
  .row {
    grid-template-columns: 1fr;
  }
  .section-header {
    color: var(--medium-color);
  }
  .navbar {
    flex-direction: column;
  }
  .navbar .logo {
    margin-top: 5px;
  }
  .navbar a {
    padding: 0 0.5rem;
  }
  .slider .content h1 {
    font-size: 7rem;
    line-height: 6rem;
  }
  .slider .content p {
    font-size: 1.8rem;
  }
  .section-header {
    font-size: 2.5rem;
  }
  .column {
    margin: 4rem;
  }
  .section-about {
    background-image: linear-gradient(
        105deg,
        rgba(85, 197, 122, 0.9) 0%,
        rgba(85, 197, 122, 0.9) 100%,
        transparent 50%
      ),
      url(/img/about.jpg);
  }
  .section-contact {
    background-image: linear-gradient(
        102deg,
        rgba(85, 197, 122, 0.9) 0%,
        rgba(85, 197, 122, 0.9) 100%,
        transparent 50%
      ),
      url(/img/address-map.png);
  }
}
@media only screen and (max-width: 992px) {
  .services .row {
    grid-template-columns: 1fr;
  }
  .cards {
    min-width: auto;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1360px) {
  .cards {
    min-width: auto;
  }
}

@media only screen and (max-height: 420px) {
  .slider .content {
    width: 40rem;
    height: 100%;
  }
  .slider .content h1 {
    font-size: 6rem;
    line-height: 6rem;
  }
  .slider .content p {
    font-size: 1.8rem;
  }
}
