.slider {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}
.slider .slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
.slider .slide:first-child {
  background: url(/img/slideshow/1.jpg) no-repeat center center/cover;
}
.slider .slide:first-child::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.slider .slide:nth-child(2) {
  background: url(/img/slideshow/2.jpg) no-repeat center center/cover;
}
.slider .slide:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.slider .slide:nth-child(3) {
  background: url(/img/slideshow/3.jpg) no-repeat center center/cover;
}
.slider .slide:nth-child(3)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.slider .current {
  opacity: 1;
}
.slider .current .content {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.7s ease-in-out 0.3s;
  margin: 0 auto;
}
.slider .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 90%;
  opacity: 0;
  /* transform: translateY(200px); */
}

.slider .content h1 {
  font-family: "Bebas Neue", cursive;
  font-size: 10rem;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 1px;
  line-height: 72px;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: var(--main-color);
}
.slider .content p {
  font-family: "Josefin Sans", sans-serif;
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 23.3999996185303px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 30px;
}

.buttons span {
  color: #fff;
  cursor: pointer;
  padding: 7px 9px;
  outline: none;
  transition: all 0.3s;
  font-size: 25px;
}
.buttons span:hover {
  color: var(--main-color);
}

.buttons #next {
  position: absolute;
  top: 50%;
  right: 15px;
}

.buttons #prev {
  position: absolute;
  top: 50%;
  left: 15px;
}

/* .buttons #down {
  position: absolute;
  bottom: 1%;
  left: 0;
  right: 0;
  text-align: center;
} */
