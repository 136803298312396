.privacy-container {
  scroll-margin-top: 100px;
  max-width: 1450px;
  margin: 0 auto;
}

.privacy-policy {
  padding: 4rem 2rem;
  background-color: var(--grey-color-light);
}

.privacy-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.effective-date {
  color: var(--grey-color-dark);
  font-style: italic;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #f0f0f0;
}

.policy-section {
  color: var(--dark-color);
  padding: 0 1rem;
}

.policy-section h3 {
  color: var(--dark-color);
  margin: 2.5rem 0 1.5rem 0;
  font-size: 2rem;
  position: relative;
  background-color: #ffffff;
}

.policy-section h4 {
  color: var(--dark-color);
  margin: 2rem 0 1rem 0;
  font-size: 1.8rem;
}

.policy-section p {
  color: var(--dark-color);
  margin-bottom: 1.5rem;
  line-height: 1.6;
  font-size: 1.6rem;
}

.policy-section ul {
  margin: 1rem 0 2rem 2rem;
  list-style-type: disc;
}

.policy-section li {
  color: var(--dark-color);
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  line-height: 1.6;
  list-style: disc;
}

.policy-section .contact-us {
  margin: 2rem 0;
  padding: 2rem;
  background-color: var(--grey-color-light);
  border-radius: 4px;
}

.policy-section .contact-us p {
  margin-bottom: 1rem;
  font-size: 1.6rem;
}

.privacy-content {
  margin-bottom: 3rem;
}

@media only screen and (max-width: 768px) {
  .privacy-policy {
    padding: 2rem 1rem;
  }
  
  .privacy-content {
    padding: 1.5rem;
  }
  
  .policy-section h3 {
    font-size: 1.8rem;
  }
  
  .policy-section h4 {
    font-size: 1.6rem;
  }
  
  .policy-section p,
  .policy-section li {
    font-size: 1.4rem;
  }
} 