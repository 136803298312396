@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Josefin+Sans:wght@300;400&family=Nunito&display=swap);
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(51, 51, 51, 0.8);
  color: #ffffff;
  width: 100%;
  min-height: 70px;
  position: fixed;
  top: 0;
  padding: 10px 30px 0 40px;
  transition: 0.5s ease-in;
}
.navbar ul {
  display: flex;
}
.navbar li {
  padding: 6px 0;
}
.navbar a {
  position: relative;
  display: block;
  padding: 10px 10px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 300;
  color: #ecf0f1;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  font-size: 1.5rem;
}
.navbar a:hover {
  color: var(--main-color);
}

.navbar a::after {
  position: absolute;
  content: "";
  top: 100%;
  left: 0;
  width: 100%;
  height: 3px;
  background: var(--main-color);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s;
}

.navbar a:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}
.navbar .logo img {
  height: 5rem;
  margin-top: 10px;
}

.top {
  background: transparent;
}

.hero {
  position: relative;
  color: #ffffff;
}
.hero * {
  z-index: 10;
}

.slider {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}
.slider .slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
.slider .slide:first-child {
  background: url(/img/slideshow/1.jpg) no-repeat center center/cover;
}
.slider .slide:first-child::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.slider .slide:nth-child(2) {
  background: url(/img/slideshow/2.jpg) no-repeat center center/cover;
}
.slider .slide:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.slider .slide:nth-child(3) {
  background: url(/img/slideshow/3.jpg) no-repeat center center/cover;
}
.slider .slide:nth-child(3)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.slider .current {
  opacity: 1;
}
.slider .current .content {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.7s ease-in-out 0.3s;
  margin: 0 auto;
}
.slider .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 90%;
  opacity: 0;
  /* transform: translateY(200px); */
}

.slider .content h1 {
  font-family: "Bebas Neue", cursive;
  font-size: 10rem;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 1px;
  line-height: 72px;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: var(--main-color);
}
.slider .content p {
  font-family: "Josefin Sans", sans-serif;
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 23.3999996185303px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 30px;
}

.buttons span {
  color: #fff;
  cursor: pointer;
  padding: 7px 9px;
  outline: none;
  transition: all 0.3s;
  font-size: 25px;
}
.buttons span:hover {
  color: var(--main-color);
}

.buttons #next {
  position: absolute;
  top: 50%;
  right: 15px;
}

.buttons #prev {
  position: absolute;
  top: 50%;
  left: 15px;
}

/* .buttons #down {
  position: absolute;
  bottom: 1%;
  left: 0;
  right: 0;
  text-align: center;
} */

.section-about {
  background-image: linear-gradient(
      105deg,
      rgba(85, 197, 122, 0.9) 0%,
      rgba(85, 197, 122, 0.9) 55%,
      transparent 50%
    ),
    url(/img/about.jpg);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
}

.section-about h3 {
  text-transform: none;
  font-weight: normal;
}

.section-about h3 strong {
  font-weight: bold;
  text-transform: none;
}

.section-about p strong {
  font-weight: 900 !important;
  color: white;
}

.services-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.services-list li {
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 15px;
  color: white;
  font-size: 16px;
  line-height: 1.6;
}

.services-list li::before {
  content: "•";
  position: absolute;
  left: 0.5em;
  color: white;
  font-size: 16px;
  line-height: 1.6;
}

.services-list li strong {
  font-weight: 900 !important;
  font-family: inherit;
  color: white;
  font-size: 16px;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.section-services {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.cards {
  display: grid;
  grid-template-columns: 50% 50%;
  background-image: linear-gradient(
    255deg,
    rgba(85, 197, 122, 1) 0%,
    rgba(85, 197, 122, 1) 50%,
    transparent 50%
  );
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  min-height: 25rem;
  min-width: 62rem;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
  margin: 1rem;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.cards h3 {
  color: var(--grey-color-dark);
}
.cards p {
  color: var(--grey-color-dark);
}

.cards:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.cards-sections {
  margin: 2.5rem;
}

.img-1 {
  background: url(/img/icons/Icon_Reefer.svg) center no-repeat;
}
.img-2 {
  background: url(/img/icons/Icon_dry_van.svg) center no-repeat;
}
.img-3 {
  background: url(/img/icons/Icon_Flatbed.svg) center no-repeat;
}
.img-4 {
  background: url(/img/icons/Icon_Step_Deck.svg) center no-repeat;
}

.section-contact {
  background-image: linear-gradient(
      105deg,
      rgba(85, 197, 122, 0.9) 0%,
      rgba(85, 197, 122, 0.9) 55%,
      transparent 50%
    ),
    url(/img/address-map.png);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 55rem;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
  margin-bottom: 3rem;
}
.contact-column {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact-logo {
  background-color: #ffffff;
  padding: 1rem 1rem 0.5rem 1rem;
  margin: 5rem;
  text-align: center;
  display: inline-block;
}
.contact-logo img {
  height: 6rem;
}

.contact-info p {
  display: flex;
  padding: 2rem 0;
  font-size: 2rem;
  align-items: center;
}

.contact-info a,
a:active,
a::after,
a:visited {
  text-decoration: none;
  color: white;
}
.contact-info .fas {
  font-size: 2.5rem;
  padding-right: 3rem;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 200px;
  font-size: 1.5rem;
}

.footer a {
  color: #ffffff;
}

.footer a:hover {
  color: #28a745;
}

.footer .social {
  align-items: center;
}

.footer .social > * {
  margin: 1rem;
}

.footer-links {
  margin: 1rem 0;
}

.footer-links a {
  font-size: 1.4rem;
  text-decoration: underline;
}

.footer p {
  margin-top: 1rem;
}

.bg-dark {
  background: #333333;
  color: #ffffff;
}

/* Google Font Imports */

/* variables */
:root {
  --width: 1280px;
  --main-color: #55c57a;
  --light-color: #7ed56f;
  --medium-color: #28b485;
  --grey-color-light: #f7f7f7;
  --grey-color-dark: #777777;
  --dark-color: #333333;
  /* --bg-image: url("../img/slideshow/img.jpg"); */
}

/* defaults */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  line-height: 1.6;
  color: #777777;
  color: var(--grey-color-dark);
}

html {
  /* This defines what 1rem is */
  font-size: 62.5%;
  font-family: "Nunito", sans-serif;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}
.container {
  max-width: 1450px;
  margin: 0 auto;
}

/* Utils */
h2 {
  font-family: "Bebas Neue", cursive;
  font-size: 5rem;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 3px;
  line-height: 72px;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: #55c57a;
  color: var(--main-color);
  padding: 2rem;
}
h3 {
  position: relative;
  display: block;
  font-family: "Josefin Sans", sans-serif;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  font-size: 1.8rem;
  margin: 1.5rem 0;
}
h4 {
  font-weight: bold;
  font-family: "Nunito", sans-serif;
}
p {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 22.3999996185303px;
  text-transform: none;
  color: #ffffff;
}
li {
  list-style: none;
  text-decoration: none;
}
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 4rem;
}
.align-center {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.row {
  display: grid;
  grid-template-columns: 50% 50%;
}
.column {
  margin: 2rem 2rem 4rem 4rem;
}

/* Headers */
.section-header {
  text-transform: uppercase;
  padding: 7rem 0rem 1rem 0;
}
/* Gallery IMG */
.react-photo-gallery--gallery {
  margin: 2rem;
  padding: 0 2rem;
}
.react-photo-gallery--gallery img {
  object-fit: cover;
  opacity: 1;
  transition: 0.3s ease-in-out;
}
.react-photo-gallery--gallery img:hover {
  opacity: 0.5;
}

/* Media Queries */

@media only screen and (max-width: 600px) and (min-width: 320px) {
  h2 {
    line-height: 5rem;
  }
  .navbar {
    flex-direction: column;
    padding: 0;
  }
  .navbar .logo img {
    height: 4rem;
    margin-top: 10px;
  }
  .navbar li {
    padding: 0;
  }
  .navbar a {
    padding: 0.5rem;
    font-size: 1.1rem;
  }

  .slider .content h1 {
    font-size: 7rem;
    line-height: 6rem;
  }
  .slider .content p {
    font-size: 1.8rem;
  }
  .slider .content {
    width: 30rem;
    height: 100%;
  }
  .buttons #next {
    position: absolute;
    top: 90%;
    right: 15px;
  }

  .buttons #prev {
    position: absolute;
    top: 90%;
    left: 15px;
  }
  .section-about {
    background-image: linear-gradient(
        105deg,
        rgba(85, 197, 122, 0.9) 0%,
        rgba(85, 197, 122, 0.9) 100%,
        transparent 50%
      ),
      url(/img/about.jpg);
  }
  .row {
    grid-template-columns: 1fr;
  }

  .column {
    margin: 2rem;
  }

  .cards {
    display: flex;
    flex-direction: column-reverse;
    background-image: none;
    min-height: 28rem;
    min-width: auto;
  }
  .cards-sections {
    padding: 2rem;
  }
  .img-1,
  .img-2,
  .img-3,
  .img-4 {
    background-color: #55c57a;
    background-color: var(--main-color);
    margin-bottom: 0;
    height: 10rem;
  }

  .section-contact {
    background-image: linear-gradient(
        102deg,
        rgba(85, 197, 122, 0.9) 0%,
        rgba(85, 197, 122, 0.9) 100%,
        transparent 50%
      ),
      url(/img/address-map.png);
  }
  .contact-logo img {
    height: 5rem;
  }
  .contact-info p {
    text-align: left;
    font-size: 1.5rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 1024px) and (min-width: 601px) {
  .row {
    grid-template-columns: 1fr;
  }
  .section-header {
    color: #28b485;
    color: var(--medium-color);
  }
  .navbar {
    flex-direction: column;
  }
  .navbar .logo {
    margin-top: 5px;
  }
  .navbar a {
    padding: 0 0.5rem;
  }
  .slider .content h1 {
    font-size: 7rem;
    line-height: 6rem;
  }
  .slider .content p {
    font-size: 1.8rem;
  }
  .section-header {
    font-size: 2.5rem;
  }
  .column {
    margin: 4rem;
  }
  .section-about {
    background-image: linear-gradient(
        105deg,
        rgba(85, 197, 122, 0.9) 0%,
        rgba(85, 197, 122, 0.9) 100%,
        transparent 50%
      ),
      url(/img/about.jpg);
  }
  .section-contact {
    background-image: linear-gradient(
        102deg,
        rgba(85, 197, 122, 0.9) 0%,
        rgba(85, 197, 122, 0.9) 100%,
        transparent 50%
      ),
      url(/img/address-map.png);
  }
}
@media only screen and (max-width: 992px) {
  .services .row {
    grid-template-columns: 1fr;
  }
  .cards {
    min-width: auto;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1360px) {
  .cards {
    min-width: auto;
  }
}

@media only screen and (max-height: 420px) {
  .slider .content {
    width: 40rem;
    height: 100%;
  }
  .slider .content h1 {
    font-size: 6rem;
    line-height: 6rem;
  }
  .slider .content p {
    font-size: 1.8rem;
  }
}

.privacy-container {
  scroll-margin-top: 100px;
  max-width: 1450px;
  margin: 0 auto;
}

.privacy-policy {
  padding: 4rem 2rem;
  background-color: var(--grey-color-light);
}

.privacy-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.effective-date {
  color: var(--grey-color-dark);
  font-style: italic;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #f0f0f0;
}

.policy-section {
  color: var(--dark-color);
  padding: 0 1rem;
}

.policy-section h3 {
  color: var(--dark-color);
  margin: 2.5rem 0 1.5rem 0;
  font-size: 2rem;
  position: relative;
  background-color: #ffffff;
}

.policy-section h4 {
  color: var(--dark-color);
  margin: 2rem 0 1rem 0;
  font-size: 1.8rem;
}

.policy-section p {
  color: var(--dark-color);
  margin-bottom: 1.5rem;
  line-height: 1.6;
  font-size: 1.6rem;
}

.policy-section ul {
  margin: 1rem 0 2rem 2rem;
  list-style-type: disc;
}

.policy-section li {
  color: var(--dark-color);
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  line-height: 1.6;
  list-style: disc;
}

.policy-section .contact-us {
  margin: 2rem 0;
  padding: 2rem;
  background-color: var(--grey-color-light);
  border-radius: 4px;
}

.policy-section .contact-us p {
  margin-bottom: 1rem;
  font-size: 1.6rem;
}

.privacy-content {
  margin-bottom: 3rem;
}

@media only screen and (max-width: 768px) {
  .privacy-policy {
    padding: 2rem 1rem;
  }
  
  .privacy-content {
    padding: 1.5rem;
  }
  
  .policy-section h3 {
    font-size: 1.8rem;
  }
  
  .policy-section h4 {
    font-size: 1.6rem;
  }
  
  .policy-section p,
  .policy-section li {
    font-size: 1.4rem;
  }
} 
